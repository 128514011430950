import { ApolloClient, HttpLink, InMemoryCache, from, Observable } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import _ from 'lodash'

import { getAuthorizationToken, logoutFromExpiredSession } from '../../authentication'
import { UNAUTHENTICATED_ROUTES } from '../../routing/routes'

/**
 * Create cache instance
 */
export const cache = new InMemoryCache()

/**
 * Creates a new HTTP connection
 * @TODO Swap this for your GraphQL URI (use an environment variable)
 */
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_HOST}/graphql`,
})

/**
 * Adds authorization header to HTTP link
 * @TODO Get the authorization token
 */
const authorizationLink = setContext(() => ({
  headers: {
    'X-Application-Name': 'UNIVERSITY_REPORT',
    authorization: getAuthorizationToken(),
  },
}))

const isJWTInvalidatedError = errors => {
  return _.find(errors, {
    message: 'Context creation failed: Invalidated Token!',
  })
}

const isJWTExpiredError = errors => {
  return _.find(errors, { message: 'Context creation failed: jwt expired' })
}

const isForbiddenError = errors => {
  return (
    _.find(errors, { message: "You can't access that!" }) ||
    _.find(errors, { extensions: { code: 'FORBIDDEN' } })
  )
}

const isInvalidApplicationError = errors => {
  return _.find(errors, { message: 'Invalid Application Name' })
}

const unauthorized = graphQLErrors => {
  return (
    isJWTExpiredError(graphQLErrors) ||
    isForbiddenError(graphQLErrors) ||
    isJWTInvalidatedError(graphQLErrors) ||
    isInvalidApplicationError(graphQLErrors)
  )
}

/**
 * Handles an error to an external service
 */
const handleErrorLink = onError(({ graphQLErrors }) => {
  if (unauthorized(graphQLErrors)) {
    return new Observable(() => {
      logoutFromExpiredSession().then(() => {
        setTimeout(() => {
          document.location.replace(UNAUTHENTICATED_ROUTES.AUTHENTICATE)
        }, 1000)
      })
    })
  }
})

/**
 * Apollo client instance
 */
export const client = new ApolloClient({
  cache,
  link: from([handleErrorLink, authorizationLink, httpLink]),
})
